@font-face {
	font-family: 'Computer Modern Serif';
	src: url('cmunrm.eot');
	src: url('cmunrm.eot?#iefix') format('embedded-opentype'),
		 url('cmunrm.woff') format('woff'),
		 url('cmunrm.ttf') format('truetype'),
		 url('cmunrm.svg#cmunrm') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'Computer Modern Serif';
	src: url('cmunbx.eot');
	src: url('cmunbx.eot?#iefix') format('embedded-opentype'),
		 url('cmunbx.woff') format('woff'),
		 url('cmunbx.ttf') format('truetype'),
		 url('cmunbx.svg#cmunbx') format('svg');
	font-weight: bold;
	font-style: normal;
}


@font-face {
	font-family: 'Computer Modern Serif';
	src: url('cmunti.eot');
	src: url('cmunti.eot?#iefix') format('embedded-opentype'),
		 url('cmunti.woff') format('woff'),
		 url('cmunti.ttf') format('truetype'),
		 url('cmunti.svg#cmunti') format('svg');
	font-weight: normal;
	font-style: italic;
}


@font-face {
	font-family: 'Computer Modern Serif';
	src: url('cmunbi.eot');
	src: url('cmunbi.eot?#iefix') format('embedded-opentype'),
		 url('cmunbi.woff') format('woff'),
		 url('cmunbi.ttf') format('truetype'),
		 url('cmunbi.svg#cmunbi') format('svg');
	font-weight: bold;
	font-style: italic;
}


