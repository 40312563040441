@font-face {
	font-family: 'Computer Modern Typewriter';
	src: url('cmuntt.eot');
	src: url('cmuntt.eot?#iefix') format('embedded-opentype'),
		 url('cmuntt.woff') format('woff'),
		 url('cmuntt.ttf') format('truetype'),
		 url('cmuntt.svg#cmuntt') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'Computer Modern Typewriter';
	src: url('cmuntb.eot');
	src: url('cmuntb.eot?#iefix') format('embedded-opentype'),
		 url('cmuntb.woff') format('woff'),
		 url('cmuntb.ttf') format('truetype'),
		 url('cmuntb.svg#cmuntb') format('svg');
	font-weight: bold;
	font-style: normal;
}


@font-face {
	font-family: 'Computer Modern Typewriter';
	src: url('cmunit.eot');
	src: url('cmunit.eot?#iefix') format('embedded-opentype'),
		 url('cmunit.woff') format('woff'),
		 url('cmunit.ttf') format('truetype'),
		 url('cmunit.svg#cmunit') format('svg');
	font-weight: normal;
	font-style: italic;
}


@font-face {
	font-family: 'Computer Modern Typewriter';
	src: url('cmuntx.eot');
	src: url('cmuntx.eot?#iefix') format('embedded-opentype'),
		 url('cmuntx.woff') format('woff'),
		 url('cmuntx.ttf') format('truetype'),
		 url('cmuntx.svg#cmuntx') format('svg');
	font-weight: bold;
	font-style: italic;
}


